const uniqueMeals = [
  " Chimichurri Seitan\t\t\t\t\t",
  " Diced Onions",
  " Sliced Green Onions",
  "2% Greek Plain Yogurt",
  "3 Cheese Pizza",
  '3/8" French Fries, Fried',
  '4.5" White Corn Tortilla',
  '6" Corn Tortilla',
  '6" Flour Tortilla',
  '6" Whole Wheat Flour Tortilla',
  "Acorn Squash",
  "Acorn Squash and Vegetable Saute",
  "Acorn Squash with Cider Drizzle",
  "Adventure Cookie",
  "Al Pastor",
  "Alfredo Sauce",
  "Apple",
  "Apple Bacon Whole Grain Pancakes",
  "Apple Cake",
  "Apple Cobbler",
  "Apple Crisp",
  "Apple Danish",
  "Apple Farro Salad ",
  "Apple Orchard Bar",
  "Apple Pie",
  "Apple Pie Cinnamon Rolls",
  "Apple Raspberry Crisp",
  "Apple Spice Muffin ",
  "Apple Strudel",
  "Apple, Cinnamon, Oatmeal Crisp",
  "Apricot Oatmeal Bars",
  "Arugula, Garbanzo,  and Fennel Salad with  Parsley Vinaigrette",
  "Asada PAOW and Peppers and Onions",
  "Asian BBQ Glazed Pork",
  "Asian Beef Pepper Steak",
  "Asian Vegetable Stir Fry with Rice",
  "Asparagus",
  "Asparagus and Roasted Red Peppers",
  "Assorted Donuts",
  "Avocado Chocolate Chia Coconut Pudding",
  "Avocado Corn Salsa",
  "Avocado and Grapefruit Salad",
  "Avocado, Papaya and Citrus Salad",
  "Avoiding Gluten Barilla Penne",
  "BBQ Baked Beans",
  "BBQ Beef Brisket",
  "BBQ Carrot Tostada, Salsa, Avocado",
  "BBQ Chicken",
  "BBQ Chicken Quarter",
  "BBQ Pork Baked Sweet Potato",
  "BBQ Potatoes",
  "BBQ Rotisserie Chicken",
  "BBQ Seitan",
  "BBQ Shredded Beef",
  "BBQ Squash Ribs",
  'BBQ Tofu "Steak"',
  "Baby Spinach",
  "Bacon",
  "Bacon Charro Beans",
  "Bacon Cheeseburger Pizza",
  "Bacon and Pea Carbonara Sauce",
  "Baharat Chicken Thigh",
  "Baharat Spiced Chickpeas",
  "Baja Style Fish Tacos",
  "Baked Beans",
  "Baked Chicken",
  "Baked Falafel",
  "Baked Fish Vera Cruz",
  "Baked French Fries",
  "Baked Macaroni and Cheese",
  "Baked PAOW",
  "Baked Pita Triangles",
  "Baked Plantains",
  "Baked Potatoes",
  "Baked Sweet Potato",
  "Baked Tilapia",
  "Baked Vegetable Ziti",
  "Baked Ziti ",
  "Baked Ziti with Beef Meat Sauce",
  "Baked Ziti with Meat Sauce",
  "Baked Ziti with Turkey Meat Sauce",
  "Balsamic Glazed Pork",
  "Balsamic Roasted Portobello Mushroom",
  "Banana",
  "Banana Bread",
  "Banana Cake",
  "Banana Chocolate Chip Muffin",
  "Banana Cream Pie",
  "Banana Foster Pancakes",
  "Banana Muffin",
  "Banana Pudding",
  "Barbacoa Beef",
  "Barbecue Chicken",
  "Barbecue Jerk Chicken Pizza",
  "Barbecue PAOW Stuffed Sweet Potato",
  "Barbecue Pork Loin Roast",
  "Barley Mushroom Pilaf",
  "Basic Sauteed Kale",
  "Basil Pesto with No Nuts",
  "Basmati Rice",
  "Basmati Rice Pilaf",
  "Basmati Rice with Cumin",
  "Basmati Rice with Peas",
  "Batter Fried Cod",
  "Batter Fried Cod ",
  "Bean and Tomato Chili ",
  "Beef Barbacoa",
  "Beef Barley Soup",
  "Beef Birria",
  "Beef Bolognese",
  "Beef Bolognese Sauce",
  "Beef Carne Asada",
  "Beef Chili",
  "Beef Fajita Mixture",
  "Beef Fajitas",
  "Beef Hamburger",
  "Beef Hot Dog",
  "Beef Masala",
  "Beef Meatballs in Marinara",
  "Beef Noodle Soup",
  "Beef Pho Broth",
  "Beef Picadillo",
  "Beef Pot Roast",
  "Beef Shepard's Pie",
  "Beef Stew",
  "Beef Stroganoff",
  "Beef Taco",
  "Beef Taco Meat",
  "Beef and Broccoli",
  "Beef, Mushroom, and Snow Pea Stir Fry",
  "Bell Pepper Salad",
  "Beyond Bolognese",
  "Beyond Burger on Bun",
  "Beyond Meatballs",
  "Beyond Meatloaf",
  "Beyond Sausage Crumbles",
  "Birthday Cake Scone",
  "Biscuits and Pork Sausage Gravy",
  "Black Bean Burger",
  "Black Bean Cheese Mushroom Enchilada",
  "Black Bean Sweet Potato Tacos Lime Slaw",
  "Black Bean and Corn Salad",
  "Black Bean and Zucchini Quesadilla",
  "Black Bean, Mango and Avocado Salad",
  "Black Beans",
  "Black Beans with Rice ",
  "Black Eyed Pea, Roasted Corn Salad",
  "Black Forest Cake ",
  "Black Olive and Artichoke Pizza",
  "Black eyed pea salad",
  "Blackened Fish",
  "Blackened Lemon Oregano Turkey Sauté",
  "Blackened Salmon",
  "Blackened Sweet Potato",
  "Blackened Tilapia",
  "Blackened Tilapia with Parmesan",
  "Blanched Broccoli",
  "Blanched Green Beans",
  "Blanched Lemon Spinach",
  "Bland Pork Loin with Gravy",
  "Blintzes with Blueberry Compote",
  "Blondies",
  "Blueberries",
  "Blueberry Breakfast Biscuit",
  "Blueberry Cobbler",
  "Blueberry Coffee Muffin ",
  "Blueberry Coffeecake",
  "Blueberry Crisp",
  "Blueberry Crumb Cake",
  "Blueberry Crumb Muffin",
  "Blueberry Danish",
  "Blueberry Mango Crisp",
  "Blueberry Muffin",
  "Blueberry Muffins",
  "Blueberry Scone",
  "Blueberry Scones",
  "Blueberry, Cinnamon, Oatmeal Crisp",
  "Bok Choy",
  "Boston Cream Pie",
  "Bourbon Chicken",
  "Bowtie Chicken Alfredo",
  "Bowtie Pasta",
  "Braised Beef ",
  "Braised Beef Pot Roast",
  "Braised Beef Tips",
  "Braised Beef with Gravy",
  "Braised Cabbage",
  "Braised Collard Greens",
  "Bratwurst",
  "Bratwurst with Sauerkraut",
  "Bread display",
  "Breakfast Ham",
  "Breakfast Pizza",
  "Breakfast Potatoes",
  "Broccoli",
  "Broccoli & Cauliflower",
  "Broccoli Cheddar Soup",
  "Broccoli Florets",
  "Broccoli Spinach Alfredo Pizza",
  "Broccoli Spinach Calzone",
  "Broccoli Tofu Stir Fry",
  "Broccoli, Carrots and Cauliflower",
  "Broccolini",
  "Broiled Tilapia",
  "Brown Basmati Rice Pilaf",
  "Brown Gravy",
  "Brown Rice",
  "Brown Rice Salad",
  "Brown Sugar Cinnamon Scone",
  "Brownies with Powdered Sugar",
  "Bruschetta Flatbread",
  "Brussels Sprout Sweet Potato Hash",
  "Brussels Sprouts",
  "Buffalo Chicken",
  "Buffalo Chicken Flatbread",
  "Buffalo Chicken Macaroni & Cheese",
  "Buffalo Chicken Pizza",
  "Buffalo Chicken Sliders",
  "Buffalo Fried Chicken",
  "Buffalo Tempeh",
  "Buffalo Vegan Chicken Nugget",
  "Buffalo Wing Sauce",
  "Bulgogi BBQ Beef ",
  "Bulgur Pilaf with Lentils",
  "Buttered Egg Noodles",
  "Buttered Parsnips",
  "Buttermilk Pancakes",
  "Butternut Squash",
  "Butternut Squash & Spinch",
  "Butternut Squash Apple Soup",
  "Butternut Squash with Chili Rub",
  "Butterscotch Parfait",
  "CHE Vegan Rice Pudding",
  "Cajun Chicken",
  "Cajun Chicken Pasta",
  "Cajun Grilled Salmon",
  "Cajun Grilled Tilapia",
  "Cajun Lentil Stew",
  "Cajun Red Beans and Rice",
  "Cajun Roasted Cauliflower Steak with Avocado Salsa",
  "Cajun Tilapia with Creole Remoulade",
  "Cajun Turkey Breast",
  "Cake with Chocolate Frosting",
  "Calabacitas ",
  "Cali Chicken Tacos",
  "Canadian Bacon",
  "Caramel Apple Cake",
  "Caramel Bread Pudding",
  "Caramel Brownies",
  "Caramelized Peppers and Onions",
  "Caribbean Beans and Rice\t",
  "Carmelized Onion Zucchini Pizza",
  "Carne Asada",
  "Carne Asada Burrito",
  "Carne Asada Soft Taco",
  "Carne Asada Style PAOW",
  "Carne Asada Style Portobello",
  "Carnitas",
  "Carnival Cookies",
  "Carolina Pulled Pork Sliders",
  "Carolina Slaw",
  "Carrot Cake",
  "Carrot Cake Muffin",
  "Carrot Cooking Techniques",
  "Carrot Ginger Bisque",
  "Carrot Quinoa Risotto",
  "Carrots",
  "Carrots with Ginger",
  "Carrots, Yellow Squash and Snow Peas",
  "Carrots,Squash,Broccoli & Mushroom",
  "Cauliflower Cheddar Soup",
  "Cauliflower Rice Tabouleh",
  "Cauliflower, Tahini, Raisins",
  "Charred Cauliflower Tacos",
  "Charred Tomato and Green Bean Salad",
  "Charro Beans",
  "Cheddar Cheese",
  "Cheddar Cheese Curds",
  "Cheddar Grits",
  "Cheese Blintz",
  "Cheese Curds",
  "Cheese Danish",
  "Cheese Grits",
  "Cheese Pizza",
  "Cheese Quesadilla",
  "Cheese Ravioli",
  "Cheese Ravioli With Creamy Marinara",
  "Cheese Ravioli with Marinara",
  "Cheese Ravioli with Sauce",
  "Cheese Sauce",
  "Cheese Tortellini",
  "Cheese and Meat Board",
  "Cheesecake Trifle",
  "Cheesecake with Chocolate Sauce",
  "Cheesecake with Raspberry Sauce",
  "Cheesecake with Strawberry Sauce",
  "Cheesecake, Raspberry and Wafers",
  "Cheesy Breadsticks",
  "Cherry Cheese Pie",
  "Cherry Cinnamon Bites",
  "Cherry Cobbler",
  "Cherry Crisp",
  "Cherry Danish",
  "Cherry Pie",
  "Cherry Pie Filling",
  "Chess Pie",
  "Chia Chocolate Mousse",
  "Chia Seed Pudding with Berries",
  "Chicago Hot Dog",
  "Chicken Apple Sausage",
  "Chicken Apple Sausage Link",
  "Chicken Burrito",
  "Chicken Curry",
  "Chicken Fajita",
  "Chicken Fajitas",
  "Chicken Gumbo",
  "Chicken Gyro",
  "Chicken Noodle Soup",
  "Chicken Pesto Flatbread",
  "Chicken Pot Pie",
  "Chicken Pot Pie with Biscuit Topping",
  "Chicken Sausage Patty",
  "Chicken Stir Fry",
  "Chicken Tacos",
  "Chicken Tender",
  "Chicken Tenders",
  "Chicken Tinga",
  "Chicken Tortilla Soup",
  "Chicken and Apricot Tagine",
  "Chicken and Farro Soup",
  "Chicken, Sausage and Okra Gumbo",
  "Chickpea Pasta with Pesto Sauce",
  "Chickpeas and Spinach",
  "Chili Cheese Dog",
  "Chili Cumin Roasted Potatoes ",
  "Chili Lime Chicken",
  "Chili Lime Chicken Tacos",
  "Chili Roasted Chayote, Zucchini",
  "Chimichurri Charred Cauliflower",
  "Chimichurri Pork Loin",
  "Chimichurri Sauce",
  "Chipotle BBQ Chicken Pizza",
  "Chipotle Pinto Beans ",
  "Chipotle Taco Salad",
  "Chocolate Cake",
  "Chocolate Cake with Chocolate Frosting",
  "Chocolate Cake with Sprinkles",
  "Chocolate Cherry Parfait",
  "Chocolate Chip Bread Pudding",
  "Chocolate Chip Cookies",
  "Chocolate Chip Pancakes",
  "Chocolate Chip Scones",
  "Chocolate Covered Pretzel Cookies",
  "Chocolate Cream Pie",
  "Chocolate Drizzle Donut Hole",
  "Chocolate Espresso Cake",
  "Chocolate Frosted Cake",
  "Chocolate Fudge Flavored Icing",
  "Chocolate Fudge Frosting",
  "Chocolate Mint Parfait",
  "Chocolate Mousse",
  "Chocolate Mousse Trifle",
  "Chocolate Oreo Pudding",
  "Chocolate Pudding",
  "Chocolate Pudding Parfait",
  "Chocolate Rice Krispie Treat",
  "Chocolate Strawberry Cake",
  "Chocolate and Coffee",
  "Chopped Broccoli ",
  "Chopped Green Bell Pepper",
  "Chopped Green Onions",
  "Chopped Iceberg Lettuce",
  "Chopped Spinach",
  "Chopped Tomatoes",
  "Chopped Zucchini",
  "Chorizo Style Tofu",
  "Chorizo Tofu",
  "Chunky Vegetable Salad",
  "Churrasco Pork",
  "Churro Pudding",
  "Cilantro Brown Rice",
  "Cilantro Lime Brown Rice ",
  "Cilantro Lime Rice ",
  "Cilantro Pesto Tofu",
  "Cilantro Rice",
  "Cilantro Serrano Pinto Beans",
  "Cinnamon Crispies",
  "Cinnamon French Toast",
  "Cinnamon Maple Biscuit",
  "Cinnamon Roll",
  "Cinnamon Roll ",
  "Cinnamon Streusel Coffee Cake",
  "Cinnamon Toast Crunch Pancakes",
  "Cinnamon Toast Crunch Treat",
  "Cinnamon and Sugar Donut",
  "Citrus Chicken Carnitas",
  "Citrus Herb Chicken Thighs",
  "Citrus Marinated Pork Loin",
  "Citrus Pork Carnitas",
  "Citrus Salad",
  "Citrus Turmeric Spiced Chicken Leg",
  "Classic BBQ Sauce",
  "Classic Banana Pudding",
  "Classic Banana Pudding Parfait",
  "Classic Cheese Quesadilla",
  "Classic Chicken Salad",
  "Classic Fish & Chips",
  "Classic Hummus",
  "Classic Minestrone Soup",
  "Classic Oatmeal",
  "Classic Shredded Coleslaw",
  "Classic Snickerdoodle Cookies",
  "Cocoa Puff Treat",
  "Coconut Cake",
  "Coconut Cilantro Lime Rice",
  "Coconut Cream Pie",
  "Coconut Curry Tempeh",
  "Coconut Curry Tofu",
  "Coconut Macaroon Brownie",
  "Coconut Pound Cake",
  "Coconut Rice",
  "Cod in Tomato Sauce",
  "Coleslaw",
  "Collard Greens",
  "Cooked Farro",
  "Cooked Italian Style Ground Sausage",
  "Cooked Tri Colored Quinoa",
  "Cookies and Cream Cake",
  "Cookies n Creme Cake",
  "Corn",
  "Corn & Black Bean Salsa",
  "Corn Bread",
  "Corn Esquites",
  "Corn Muffin",
  "Corn Salsa",
  "Cornbread",
  "Cornbread Stuffing",
  "Country Ham",
  "Crackers, Graham, 2 Ct",
  "Cranberry Orange Bars",
  "Cranberry Orange Scone",
  "Cream Cheese Brownies",
  "Cream Cheese Turnover",
  "Cream Style Corn",
  "Cream of Chicken with Rice Soup",
  "Cream of Mushroom Soup",
  "Cream of Rice",
  "Cream of Wheat",
  "Creamed Corn with Red Pepper",
  "Creamsicle Coconut Muffins",
  "Creamy Polenta",
  "Creole Salmon with Creole Remoulade",
  "Crispy Breaded Fish Filet",
  "Crispy Fish Taco",
  "Crispy Hashbrowns",
  "Crispy Parmesan French Fries",
  "Crispy Tortilla Chips",
  "Croissant",
  "Crumbled Feta Cheese",
  "Crushed Red Pepper",
  "Cuban Beef Picadillo with Rice",
  "Cuban Black Bean Soup",
  "Cuban Black Beans",
  "Cuban Red Beans",
  "Cubed Cantaloupe",
  "Cubed Honeydew",
  "Cubed Pineapple",
  "Cubed Tempeh",
  "Cubed Tofu",
  "Cubed Watermelon",
  "Cucumber & Tomato Salad with Lemon",
  "Cucumber Salad",
  "Cucumber and Onion Salad",
  "Cucumber, Tomato & Pepper Salad",
  "Cucumber, Tomato, Parsley Salad",
  "Cumin Coriander Butternut Squash",
  "Cumin Roasted Cauliflower",
  "Curly-Q French Fries",
  "Curried Black Pepper Tilapia",
  "Curried Brown Rice and Grain Blend",
  "Curried Chickpea with Quinoa, Pita",
  "Curried Quinoa and Mango Salad",
  "Curried Roasted Vegetables",
  "Curried Tofu Scramble",
  "Curried Vegetable Chickpea Hash",
  "Curry Chicken Salad",
  "Curry Roasted Potato Vegetable Stew",
  "Curry Roasted Root vegetables",
  "Cut Out Cookies",
  "Dal Spinach Curry with Coconut Milk",
  "Dal and Spinach Coconut Curry",
  "Death By Chocolate Cake",
  "Delhi Style Tofu Curry",
  "Deluxe Blondies",
  "Diced Bacon",
  "Diced Green Peppers",
  "Diced Ham",
  "Diced Jalapeno Pepper",
  "Diced Potato, Pepper, and Onion Hash",
  "Diced Potatoes",
  "Diced Roasted Sweet Potato",
  "Diced Smoked Ham",
  "Diced Tomatoes",
  "Dijon Herb Crusted Fish",
  "Dill Carrots and Zucchini",
  "Dinner Roll",
  "Divinity Brownies",
  "Double Chocolate Chip Cookie",
  "Double Chocolate Chip Cookies",
  "Double Chocolate Muffins",
  "Dried Apricots",
  "Dried Banana Chips",
  "Dried Cherries",
  "Dried Oregano",
  "Edamame",
  "Edamame Dumpling",
  "Edamame Guacamole ",
  "Edamame Succotash",
  "Egg Salad",
  "Egg Whites",
  "Eggplant Parmesan",
  "Eggplant Parmesan\t\t\t\t\t",
  "Eggplant, Tomatoes, Onions",
  "Eggs",
  "El Campo Bowl",
  "Elbow Pasta",
  "Escarole and White Bean Salad",
  "Espresso Brownies",
  "Espresso Chili Pulled Pork ",
  "FIT Tuna Salad",
  "FIT: Blanched Broccoli",
  "Farro Mushroom Risotto ",
  "Farro Peach Cereal",
  "Farro Pilaf",
  "Fennel Beet Quinoa",
  "Fennel and Orange Salad",
  "Fettuccine Alfredo ",
  "Fettuccini Noodles, Cooked",
  "Fire Braised Beef Barbacoa",
  "Fire Braised Citrus Chicken",
  "Fire Roasted Corn Chowder",
  "Fish Florentine",
  "Fish with Celery Sauce",
  "Flatbread Mediterranean ",
  "Flour Tortillas",
  "Four Cheese Flat Bread",
  "Fragrant Lentil Rice",
  "French Fries",
  "French Toast",
  "French Toast Casserole",
  "French Toast Sticks",
  "Fresh Asian Blend Vegetables",
  "Fresh Basil",
  "Fresh Berries",
  "Fresh Broccoli and Cauliflower",
  "Fresh Cauliflower",
  "Fresh Chopped Basil",
  "Fresh Chopped Garlic",
  "Fresh Churro and Chocolate",
  "Fresh Cilantro",
  "Fresh Cut Fries",
  "Fresh Fruit",
  "Fresh Fruit Medley",
  "Fresh Fruit Salad",
  "Fresh Ginger Root",
  "Fresh Green Beans",
  "Fresh Honeydew",
  "Fresh Pineapple",
  "Fresh Scalloped Potatoes",
  "Fresh Steamed Broccoli",
  "Fresh Steamed Carrots",
  "Fried Chicken",
  "Fried Chicken Sandwich",
  "Fried Corn Tostada",
  "Fried Fish",
  "Fried Hash Brown Patty",
  "Fried Onion Rings",
  "Fried Plantains",
  "Fried Wonton Strips",
  "Froot Loop Cereal Bar",
  "Frosted Flakes Cereal Bars",
  "Garam Masala Roasted Chickpea",
  "Garam Masala Tilapia",
  "Garbanzo Loaf with Marinara ",
  "Garden Burger",
  "Garlic & Herb Breadstick",
  "Garlic & Herb Olive Oil Mashed Potatoes ",
  "Garlic Breadstick",
  "Garlic Breadstick ",
  "Garlic Broccoli",
  "Garlic Chili Yakisoba Noodle",
  "Garlic Chive Mashed Potatoes",
  "Garlic Herb Pork Loin",
  "Garlic Herb Roast Beef",
  "Garlic Herb Smashed Potatoes",
  "Garlic Hummus",
  "Garlic Knots",
  "Garlic Mashed Potatoes",
  "Garlic Mashed Potatoes\t\t\t\t\t",
  "Garlic Rice",
  "Garlic Roasted Broccoli and Cauliflower",
  "Garlic Rosemary Pork Loin",
  "Garlic Rosemary Potatoes",
  "Garlic Sticks",
  "Garlic and Spice Roasted Broccoli",
  "General Tso's Sauce",
  "Ginger Garlic Green Beans",
  "Ginger Miso Glazed PAOW",
  "Ginger Soy Seitan Pepper Steak",
  "Gingered Snow Peas",
  "Glazed Carrots",
  "Glazed Carrots ",
  "Golden Beet Salad ",
  "Golden Jewel Blend",
  "Grated Parmesan Cheese",
  "Greek Fries",
  "Greek Salad",
  "Greek Salad with Greek Vinaigrette",
  "Greek Yogurt Cherry Danish",
  "Green Bean Saute",
  "Green Beans",
  "Green Beans ",
  "Green Beans and Carrots",
  "Green Beans and Cherry Tomatoes",
  "Green Beans and Roasted Red Pepper",
  "Green Beans, Leeks, Fennel, Dill",
  "Green Peas",
  "Green Seedless Grapes",
  "Grilled Adobo Chicken",
  "Grilled Asparagus",
  "Grilled Cheese Sandwich",
  "Grilled Chicken",
  "Grilled Chicken Thigh",
  "Grilled Corn Polenta",
  "Grilled Flank Steak",
  "Grilled Flank Steak ",
  "Grilled Poblano Tacos",
  "Grilled Salmon",
  "Grilled Sesame Tofu",
  "Grilled Vegan Burgers",
  "Grilled Veggie Pizza",
  "Grilled Zucchini & Squash",
  "Grilled Zucchini Slices",
  "Grilled Zucchini w/ Tomatoes",
  "Grilled onions",
  "Grits",
  "Grits, Jalapeno Cheese",
  "Ground Cinnamon",
  "Ground Turkey Filling",
  "Ground Turkey Tinga with Chorizo",
  "Guacamole",
  "Gyro Pit Grilled Chicken",
  "Ham Glaze",
  "Ham with Brown Sugar Glaze",
  "Ham with Cranberry Glaze",
  "Hamburger Patty",
  "Hamburger on Bun",
  "Hand Cut Fries",
  "Hard Boiled Egg",
  "Harissa Crusied Chicken",
  "Harissa Grilled Chicken",
  "Harissa Roasted Cauliflower",
  "Harissa Rubbed Pork ",
  "Harvest Chicken Salad",
  "Hash Brown Patty",
  "Hash Browns",
  "Hawaiian Garlic Noodles",
  "Hawaiian Stir-Fried Tempeh",
  "Hearty Beef Meat Sauce",
  "Herb Citrus Whole Chicken",
  "Herb Crusted Tofu",
  "Herb Roasted Butternut Squash",
  "Herb Roasted Potato Wedges",
  "Herb and Lemon Brown Rice and Quinoa",
  "Herbed Brown Rice",
  "Herbed Cauliflower Rice",
  "Herbed Couscous",
  "Herbed Green Beans",
  "Herbed Noodles",
  "Herbed Penne Pasta",
  "Herbed Quinoa",
  "Herbed Rice Pilaf",
  "Herbed Steamed Brown Rice",
  "Homemade Baked Corn Tortilla Chips",
  "Homemade Tuna Salad",
  "Honey Glazed Cake Donut",
  "Honey Glazed Ham",
  "Honey Glazed Salmon",
  "Hot & Spicy Tofu",
  "Hot Dog",
  "Hot Dog (small)",
  "Hot Honey Chicken Tenders",
  "Hush Puppies",
  "Iced Brownies",
  "Iced Lemon Cookie Bar",
  "Impossible Bolognese",
  "Impossible Taco Meat",
  "Inside-Out Cake",
  "Island Style Curry Chicken",
  "Italian Beef",
  "Italian Beyond Sausage Crumbles",
  "Italian Herb Roasted Chicken Quarter",
  "Italian Meatball Flatbread",
  "Italian Meatballs",
  "Italian Sausage Flatbread",
  "Italian Sausage with Peppers",
  "Italian Sloppy Joes",
  "Italian Spiced Zucchini",
  "Italian Wedding Soup",
  "Jackfruit and Tofu Coconut Fried Rice",
  "Jalapeno Sweet Cornbread",
  "Jamaican Jerk Chicken",
  "Jamaican Jerk Chicken Thighs",
  "Jasmine Pilaf, Mushrooms,Peas",
  "Jasmine Rice",
  "Jerk Chicken",
  "Jerk Chicken Flatbread",
  "Jerk Tofu",
  "Jerk Turkey",
  "Julienne Bok Choy",
  "Kale Tofu Scramble Breakfast Taco",
  "Kale and Brussels",
  "Kale, Quinoa, Butternut Squash Bowl",
  "Key Lime Pie",
  "Kidney Beans",
  "Kitchen Sink Cookie",
  "Korean BBQ Chicken Bao",
  "Korean Beef Tacos",
  "Kosher Parve Potato Latkes",
  "Kung Pao Tofu",
  "Lamb and Beef, Gyro Meat",
  "Latin Spiced Pork Tenderloin",
  "Latin Tofu Scramble",
  "Lemon Baked Cod",
  "Lemon Bar",
  "Lemon Blueberry Bar",
  "Lemon Blueberry Cookie",
  "Lemon Cake",
  "Lemon Coconut Cake",
  "Lemon Couscous",
  "Lemon Cream Pie",
  "Lemon Danish",
  "Lemon Dill Cod",
  "Lemon Dill Sauce",
  "Lemon Dill Tilapia",
  "Lemon Ginger Collard Greens",
  "Lemon Herb Cod",
  "Lemon Meringue Pie",
  "Lemon Mousse",
  "Lemon Parsley Salmon ",
  "Lemon Pepper Chicken Thigh",
  "Lemon Pepper Fish",
  "Lemon Pepper Grilled Salmon",
  "Lemon Poppy Bread",
  "Lemon Poppy Cake, Lemon Icing",
  "Lemon Poppy Seed Loaf, Lemon Glaze",
  "Lemon Poppyseed Pound Cake",
  "Lemon Pudding",
  "Lemon Pudding Parfait",
  "Lemon Rice Pilaf",
  "Lemon Roasted Broccoli",
  "Lemon Rosemary Chicken Thigh",
  "Lentil Bolognese",
  "Lentil Dahl",
  "Lentil Dal with Coconut",
  "Lentil Loaf",
  "Lentil Sloppy Joe Filling",
  "Lentils with Kale, Mushrooms & Farro",
  "Lettuce Leaf",
  "Light Brown Sugar",
  "Lime Grilled Tofu Steak",
  "Limes",
  "Linguine",
  "Lo Mein Noodles",
  "Lo Mein Noodles with Vegetables",
  "Low Fat Strawberry Yogurt",
  "Low Fat Vanilla Yogurt",
  "Low-Fat Blueberry Yogurt",
  "Lucky Charms Pancakes",
  "Lucky Charms Treats",
  "Lyonnaise Potatoes",
  "Lyonnaise Zucchini & Summer Squash",
  "M&M Blonde Brownies",
  "M&M Brownie",
  "M&M Rice Krispie Treats",
  "Mac and No-Cheese",
  "Macaroni & Cheese",
  "Macaroni & Cheese with Tomato",
  "Magic Chocolate Coconut Bar",
  "Mandarin Orange Sections",
  "Mandarin Orange chicken",
  "Manicotti Alfredo",
  "Manicotti with Marinara",
  "Manicotti with Marinara Sauce",
  "Maple Mashed Sweet Potatoes",
  "Maple Mustard Butternut Squash",
  "Maple Spice Bread",
  "Marble Cake",
  "Margarita Chicken Pasta",
  "Margherita Flat Bread Pizza",
  "Margherita Pizza",
  "Marinara Sauce",
  "Marinara Sauce ",
  "Marinated Snap Peas and Mushrooms",
  "Mashed Potatoes",
  "Mashed Red Potatoes",
  "Mashed Yukon Gold Potatoes",
  "Meat Lover's Pizza",
  "Meatball Parmesan Sub",
  "Meatball Pizza",
  "Meatball Sub",
  "Meatballs",
  "Meatless Black Bean Burger",
  "Meatless Breakfast Sausage Patty",
  "Meatless Chicken Tender",
  "Meatless Meatballs",
  "Meatless Vegetarian Sausage Patty",
  "Meatloaf",
  "Mediterranean Chopped Salad",
  "Mediterranean Flatbread",
  "Mediterranean Greek Salad",
  "Mexican Brown Rice",
  "Mexican Pinto Beans",
  "Mexican Red Rice",
  "Mexican Rice",
  "Middle Eastern Lamb Shoulder",
  "Middle Eastern Roasted Vegetables",
  "Mini Apple Cinnamon Scone",
  "Mini Apple Turnover",
  "Mini Banana Muffin",
  "Mini Cheesecake",
  "Mini Chocolate Croissant",
  "Mini Marshmallows",
  "Mini Pork Al Pastor Taco",
  "Mini Salted Caramel Danish",
  "Mint Brownies",
  "Minty Zucchini Soup",
  "Mo Blueberry Muffins",
  "Moist Apple Muffins",
  "Mojo Pork",
  "Mongolian Beef",
  "Monkey Bread Muffins",
  "Moroccan Beef and Tomato",
  "Moroccan Chicken",
  "Moroccan Couscous",
  "Moroccan Spicy Vegetables",
  "Mushroom Flatbread",
  "Mushroom Jack Fajita",
  "Mushroom Pizza",
  "Mushroom Stew Bordelaise",
  "Mushroom Street Tacos",
  "Mushroom and Barley Soup",
  "Mushroom and Cheese Frittata",
  "Mushroom and Parmesan Farro Risotto",
  "Mushroom and Spinach Couscous",
  "Mushrooms, Tomatoes and Spinach",
  "Mustard Greens, Chili, Garlic",
  "Naan Bread",
  "Naan Falafel",
  "Naan Flatbread",
  "Nashville Hot Chicken",
  "Nashville Hot Fried Tofu",
  "Neapolitan Mac & Cheese",
  "New England Clam Chowder",
  "New Orleans Red Beans",
  "No-Cheese Sauce",
  "Not So Sloppy Joe w/ Tempeh",
  "Oatmeal",
  "Oatmeal Cookie, Keebler",
  "Oatmeal Raisin Cookie",
  "Oatmeal Raisin Cookies",
  "Oats 'n Honey Granola",
  "Okra with Tomatoes and Coriander",
  "Old Bay Grilled Cod",
  "Olive Oil",
  "Olive Oil Mashed Potatoes",
  "Omelet Bar",
  "Onions and Peppers",
  "Orange Basil Carrots",
  "Orange Beef Stir Fry",
  "Orange Blossom Muffin",
  "Orange Chicken\t\t\t\t\t",
  "Orange Cranberry Coffeecake",
  "Orange Cranberry Muffin",
  "Orange Dreamsicle Delight",
  "Orange French Toast",
  "Orange and Beet Salad",
  "Orange, Ancho Chile Chayote",
  "Oreo Blonde Brownies",
  "Oreo Crumb Muffins",
  "Oreo Crumbles",
  "Oreo Fudge Cake",
  "Organic Gala Apple",
  "Orzo Pasta",
  "Oven Baked Tilapia",
  "PAOW Mushroom and Barley Stew",
  "PAOW Soy and Vegetable Stir Fry",
  "PAOW Spicy Tomato and Red Bean Chili",
  "PAOW Vegetable Stir-Fry with Tikka Masala",
  "Pan Rolls",
  "Pan Seared Tilapia",
  "Pancakes",
  "Papas y Rajas Tacos",
  "Paprika Roasted Cauliflower",
  "Parmesan Herbed Garlic Bread",
  "Parmesan Mashed Potatoes",
  "Parmesan Roasted Carrots",
  "Pasta and Meat Sauce",
  "Pasta with Marinara",
  "Pasta, Italian Sausage, Tomato Sauce",
  "Pear Butterscotch Crisp",
  "Peas and Carrots",
  "Penne Pasta",
  "Penne Pomodoro",
  "Penne alla Bacon Carbonara",
  "Penne with Beef Meatballs",
  "Penne with Creamy Marinara",
  "Penne with Herbs",
  "Penne with Marinara",
  "Penne with Sausage and Tomatoes",
  "Pepper Stir Fry",
  "Peppercorn Roast Beef",
  "Peppermint Chip Cookie",
  "Pepperoni Pizza",
  "Peri Peri Tofu",
  "Peruvian Sweet Black Beans, Coconut",
  "Pesto Chicken Pizza",
  "Philly Steak",
  "Philly Steak and Cheese Pizza",
  "Pickle Relish, Sweet",
  "Pico de Gallo",
  "Pineapple",
  "Pineapple Coconut Cinnamon Roll",
  "Pineapple Glazed Ham",
  "Pineapple Pork Curry",
  "Pineapple and Ham Hawaiian Pizza",
  "Pinto Beans",
  "Pita Chips",
  "Plain Baked Cod",
  "Plain Couscous",
  "Plantiful Bowl with spinach and grains",
  "Polenta",
  "Polish Sausage Sandwich",
  "Pollock Bites",
  "Pork Al Pastor",
  "Pork Bratwurst",
  "Pork Carnitas",
  "Pork Carnitas Tacos",
  "Pork Chorizo, Turkey, and Potato Tinga",
  "Pork Lo Mein",
  "Pork Loin with Mango Chipotle Glaze",
  "Pork Sandwich",
  "Pork Sausage Link",
  "Pork Sausage Patty",
  "Pork Souvlaki",
  "Pot Roast with Beef Gravy",
  "Potato Latkes",
  "Potato Roll",
  "Potato Wedges",
  "Potatoes O'Brien",
  "Potsticker Sauce",
  "Pound Cake with Blueberry Topping",
  "Powdered Donut Hole",
  "Pozole Verde",
  "Puerto Rican Rotisserie Chicken",
  "Puff Pastry",
  "Pulled Pork",
  "Pumpkin Bread",
  "Pumpkin Cake",
  "Pumpkin Cheesecake Parfait",
  "Pumpkin Loaf with Pumpkin Seeds",
  "Pumpkin Muffin",
  "Pumpkin Pie",
  "Pumpkin Pie with Whip Topping",
  "Pumpkin Spice Overnight Oatmeal",
  "Quinoa",
  "Quinoa & Chickpea Wrap",
  "Quinoa Cereal with Fresh Berries",
  "Quinoa Cranberry Pilaf",
  "Quinoa and ChickpeaTabouleh",
  "Quinoa and Corn Salad",
  "Quinoa and Lentil Salad",
  "Radish Jicama Cucumber Salad",
  "Rainbow Fruit Salad",
  "Rainbow Sprinkles",
  "Raisin Bran Muffin",
  "Raisins",
  "Ranch Breakfast Potatoes",
  "Raspberry Danish",
  "Raspberry Filled Cookie",
  "Raspberry Monkey Bread",
  "Raspberry Oatmeal Bar",
  "Raspberry Oatmeal Bars",
  "Raspberry Poppy Seed Muffin",
  "Raspberry Swirl Brownie Cheesecake",
  "Ratatouille",
  "Ratatouille with Cannellini Beans",
  "Red Beans and Rice",
  "Red Cabbage Cilantro Slaw",
  "Red Chile Pork Pozole",
  "Red Dahl & Sweet Potatoes",
  "Red Grapes",
  "Red Lentils with Cilantro and Mint",
  "Red Pepper and Snow Peas",
  "Red Potatoes with Onions and Peppers",
  "Red Quinoa, Corn, Tomato",
  "Red Rice",
  "Red Roasted Potatoes",
  "Red Velvet Cake",
  "Refried Bean Burrito w/ Cilantro Rice",
  "Refried Beans",
  "Refried Pinto Beans",
  "Rice Krispie Treat",
  "Rice Krispy Treat with Coconut Oil",
  "Rice Noodles",
  "Rice Pilaf",
  "Rice Pilaf with Mushrooms",
  "Rice and Beans",
  "Rice and Peas Curry",
  "Rigatoni",
  "Rigatoni Mezzi with Italian Sausage",
  "Rigatoni Pasta",
  "Rigatoni with Alfredo",
  "Roast Beef",
  "Roast Butternut Squash",
  "Roast Eye Round of Beef",
  "Roast Pork Loin",
  "Roast Pork Loin with Gravy",
  "Roast Turkey",
  "Roasted Acorn Squash",
  "Roasted Asparagus",
  "Roasted Beet & Barley Salad",
  "Roasted Broccoli",
  "Roasted Brussels Sprouts",
  "Roasted Butternut Squash",
  "Roasted Carrots ",
  "Roasted Carrots, Cauliflower, Brussels",
  "Roasted Cauliflower",
  "Roasted Cauliflower Florets",
  "Roasted Corn",
  "Roasted Corn Chowder Soup",
  "Roasted Corn Salsa",
  "Roasted Corn and Peppers",
  "Roasted Eggplant",
  "Roasted Garlic Kale",
  "Roasted Herb Chicken",
  "Roasted Herb Potatoes",
  "Roasted Mixed Vegetables",
  "Roasted Moroccan Chickpeas",
  "Roasted Potato Salad",
  "Roasted Potatoes",
  "Roasted Potatoes with Fresh Herbs",
  "Roasted Red Bliss Potatoes w/ Onion",
  "Roasted Red Pepper Hummus",
  "Roasted Red Potatoes ",
  "Roasted Squash",
  "Roasted Sweet Potato",
  "Roasted Sweet Potato Hash with Peppers ",
  "Roasted Sweet Potato Salad ",
  "Roasted Sweet Potatoes",
  "Roasted Tempeh and Vegetables",
  "Roasted Tomato Salsa",
  "Roasted Tomatoes",
  "Roasted Turkey  Breast",
  "Roasted Vegetable",
  "Roasted Vegetable Flat Bread Fold",
  "Roasted Vegetable Mix",
  "Roasted Vegetable Pizza",
  "Roasted Vegetable Salad",
  "Roasted Vegetables",
  "Roasted Vegetables ",
  "Roasted Yellow Squash",
  "Roasted Zucchini",
  "Roasted Zucchini and Yellow Squash",
  "Rocky Road Pudding",
  "Root Beer Glazed Ham",
  "Rosemary Garlic Leg of Lamb",
  "Rosemary Roasted New Potatoes",
  "Rosemary White Balsamic Chicken",
  "Rotini Pasta",
  "Rotisserie Style Chicken",
  "SR Steamed Carrots",
  "Sage Rubbed Turkey Breast",
  "Salmon, Tomato, Olive and Capers",
  "Salsa",
  "Salsa Fresca",
  "Salsa Rojo",
  "Salsa with Roasted Corn",
  "Santa Fe Chicken Bean Soup",
  "Sausage Cheese Pizza",
  "Sausage Flatbread Pizza",
  "Sausage Pizza",
  "Sausage, Peppeand Onion Sandwich",
  "Sausage, Pepper, Onion Sandwich",
  "Sauteed Breakfast Potatoes",
  "Sauteed Green Beans and Sweet Onions",
  "Sauteed Kale",
  "Sauteed Mixed Vegetables",
  "Sauteed Mushrooms",
  "Sauteed Onions",
  "Sauteed Peppers",
  "Sauteed Peppers and Onions",
  "Sauteed Spiced Zucchini",
  "Sauteed Spinach",
  "Sauteed Spinach with Garlic",
  "Sauteed Squash",
  "Sauteed Summer Vegetables",
  "Sauteed Swiss Chard",
  "Sauteed Tomatoes with Kale",
  "Sauteed Vegetables",
  "Sauteed Zucchini",
  "Sautéed Green Beans Onions Tomatoes",
  "Sautéed Green Beans, Onions, Tomatoes",
  "Sautéed Spinach and Onion",
  "Savory Brown Rice",
  "Scallion Jasmine Rice",
  "Scalloped Potatoes",
  "Scones",
  "Scrambled Eggs",
  "Sea Salt Caramel Brownie",
  "Seasoned Brown Rice",
  "Seasoned Brown Rice Pilaf",
  "Seasoned Edamame",
  "Seasoned French Fries",
  "Seasoned Green Beans",
  "Seasoned Hash Browns",
  "Seasoned Pinto Beans",
  "Seasoned Spinach",
  "Seasoned Yellow Squash",
  "Seitan for Fajitas",
  "Semi Sweet Chocolate Chips",
  "Sesame Blistered Shishito Peppers",
  "Sesame Brown Rice ",
  "Sesame Garlic Broccoli",
  "Sesame Ginger Udon Noodles",
  "Sesame Green Beans",
  "Sesame Noodles",
  "Sesame Snap Pea Blend",
  "Sesame Vegetable Stir Fry",
  "Shaved Brussel Sprouts & Kale Salad",
  "Shaved Mushroom & Spinach Pizza",
  "Shawarma Spiced PAOW",
  "Shepards Pie with Biscuits & Gravy",
  "Shortbread Cookie",
  "Shredded Cheddar Cheese",
  "Shredded Coconut",
  "Shredded Iceberg Lettuce",
  "Shredded Pepper Jack Cheese",
  "Shredded Romaine",
  "Shredded Sharp Cheddar Cheese",
  "Shredded Steamed potatoes",
  "Shrimp Alfredo with Penne",
  "Shrimp n Grits with Bacon",
  "Signature Mac & Cheese ",
  "Signature Moose Cookie",
  "Simple Sauteed Spinach",
  "Sliced American Cheese",
  "Sliced Black Olives",
  "Sliced Cucumber",
  "Sliced Dill Pickles",
  "Sliced Jalapeno",
  "Sliced Jalapeno Pepper",
  "Sliced Mushrooms",
  "Sliced Red Bell Pepper",
  "Sliced Red Onion",
  "Sliced Sharp Cheddar Cheese",
  "Sliced Strawberries",
  "Sliced Swiss Cheese",
  "Sliced Tomatoes",
  "Sloppy Joe Sandwich",
  "Smashed Black Beans",
  "Smoked BBQ Tofu ",
  "Smoked Portobellos and Tempeh",
  "Snap Peas",
  "Snicker Doodles",
  "Snickerdoodle Cookies",
  "Snow Peas",
  "Sofritas Black Bean Bowl",
  "Soft Chicken Taco",
  "Sour Cream",
  "Southwest Beef Taco Meat",
  "Southwest Turkey Burger with Guacamole",
  "Southwest Turkey Taco Meat",
  "Southwest Vegan Tofu Scramble",
  "Southwestern Beef Stew",
  "Southwestern Panko Crusted Tilapia",
  "Southwestern Tofu Scramble Tacos",
  "Spaghetti",
  "Spaghetti Squash, Mushrooms, Tomato",
  "Spaghetti with Turkey Meat Sauce",
  "Spanish Green Rice",
  "Spanish Rice",
  "Spiced Baked Sweet Potatoes",
  "Spiced Beef with Tomatoes",
  "Spiced Brown Sugar Brussels Sprouts",
  "Spiced Coconut Rice",
  "Spiced Collard Greens, Tomatoes",
  "Spiced Spaghetti Squash",
  "Spiced and Herbed Potatoes",
  "Spicy Black Beans",
  "Spicy Black Beans ",
  "Spicy Braised Bok Choy",
  "Spicy Chicken Fettuccine Alfredo",
  "Spicy Chipotle Chicken Sandwich",
  "Spicy Collard Greens",
  "Spicy Fettuccine Alfredo",
  "Spicy Green Beans ",
  "Spicy Quinoa",
  "Spicy Salmon",
  "Spicy Thai Soy Vegetable Soup",
  "Spicy Tofu Scramble",
  "Spicy Vodka Sauce Spaghetti",
  "Spinach Artichoke Hummus",
  "Spinach Hummus",
  "Spinach, Kale and Shallots",
  "Split Pea Soup",
  "Squash, Zucchini, Peppers and Carrots",
  "Sriracha Egg Salad",
  "Sriracha Honey Grilled Salmon",
  "Sriracha Hummus",
  "Sriracha Roasted Cauliflower",
  "Srircha Hummus",
  "Steak Cut French Fries",
  "Steak Fries",
  "Steamed Broccoli",
  "Steamed Broccoli ",
  "Steamed Broccoli and Cauliflower",
  "Steamed Brown Rice",
  "Steamed Cabbage",
  "Steamed Carrots",
  "Steamed Cauliflower",
  "Steamed Corn Kernels",
  "Steamed Green Beans ",
  "Steamed Green Peas",
  "Steamed Herbed Zucchini",
  "Steamed New Potatoes",
  "Steamed PAOW",
  "Steamed Peas",
  "Steamed Red Quinoa",
  "Steamed Rice",
  "Steamed Spinach",
  "Steamed Yellow Squash ",
  "Steamed Zucchini",
  "Stewed Black Beans with Tomatoes and Onions",
  "Stewed Pinto Beans with Tomatoes and Onions",
  "Sticky Buns",
  "Sticky Chia Brown Rice with Fried Egg",
  "Stir Fried Baby Bok Choy",
  "Stir Fry Bok Choy",
  "Strawberry Biscuit Shortcake",
  "Strawberry Cake",
  "Strawberry Chia Seed Pancakes",
  "Strawberry Coffeecake",
  "Strawberry Cream Cheese Croissant",
  "Strawberry Cream Cheese Danish",
  "Strawberry Cream Cheese Icing",
  "Strawberry Donut Holes",
  "Strawberry Greek Muffin",
  "Strawberry Iced Yellow Cake",
  "Strawberry Oatmeal Bars",
  "Strawberry Shortcake Danish",
  "Stuffed Shells with Marinara Sauce",
  "Sugar Cookies",
  "Sugar Snap Peas",
  "Sun-dried Tomato Portobello Calzone",
  "Sunbutter Cheerio Bar",
  "Sunbutter Oatmeal Breakfast Cookies",
  "Super Veggie Soup",
  "Supreme Pizza",
  "Sweet Chili Green Beans",
  "Sweet Cornbread",
  "Sweet Dried Pepitas",
  "Sweet Funfetti Pizza",
  "Sweet Orange Roasted Carrots",
  "Sweet Oreo Pizza",
  "Sweet Potato Chickpea Spinach Vindaloo",
  "Sweet Potato Fries",
  "Sweet Potato Hash with Kale",
  "Sweet Potato Korma with Coconut Milk",
  "Sweet Potato Pancake",
  "Sweet Potato Pie",
  "Sweet Potato Tater Tots",
  "Sweet Potato Tots",
  "Sweet Potato Wedges",
  "Sweet Potatoes",
  "Sweet S'mores Pizza",
  "Sweet Thai Chili Lime Sauce",
  "Sweet Thai Chili Sauce",
  "Sweet and Sour Chicken",
  "Sweet and Sour Pork",
  "Sweet and Sour Tofu",
  "Sweet n Spicy Chicken Bacon Biscuit",
  "Swiss Chard",
  "Szechuan Pork Stir Fry",
  "Tacos Al Pastor",
  "Tahini Sauce",
  "Tater Tots",
  "Tempeh Vegetable Picatta",
  "Teriyaki Chicken",
  "Texas Style Shredded Pork",
  "Thai Basil Tofu",
  "Thai Marinated Tofu Noodle Bowl",
  "Thai Red Curry Coconut Stew",
  "Thai Style Red Curry Salmon",
  "Thai Sweet Potatoes",
  "Thai Tofu",
  "Thyme Roasted Potatoes",
  "Thyme and Garlic Roasted Turkey",
  "Thyme and Garlic Roasted Turkey Breast",
  "Tilapia Valencia with Creamy Polenta",
  "Tilapia Vera Cruz",
  "Tilapia with Cherry Tomatoes",
  "Tilapia with Herbs",
  "Tilapia with Mango Sweet Chili Sauce",
  "Toasted Beef Meatball Sub",
  "Tofu Coconut Curry",
  "Tofu Lo Mein",
  "Tofu Mushroom Scramble",
  "Tofu Scramble",
  "Tofu Vegetable Fried Rice",
  "Tofu Vegetable Lasagna",
  "Tomatillo Avocado Salsa",
  "Tomatillo Salsa",
  "Tomatillo and Green Chili Salsa",
  "Tomato & Spinach Cheese Pizza",
  "Tomato Basil Green Beans",
  "Tomato Basil Soup",
  "Tomato Braised Beef",
  "Tomato Couscous",
  "Tomato Cucumber Salad",
  "Tomato Olive Salad",
  "Tomato, Zucchini & Yellow Squash",
  "Tortellini with Alfredo Sauce",
  "Tortellini with Marinara",
  "Tortellini with Marinara Sauce",
  "Tortilla Chips",
  "Traditional Gyro",
  "Tres Leches Cake",
  "Triple Chocolate Chunk Cookie",
  "Trix Pancakes",
  "Tropical Fruit Salad with Coconut",
  "Tuna Salad",
  "Tuna and Noodle Casserole",
  "Turkey Bacon",
  "Turkey Burger on Bun",
  "Turkey Chili",
  "Turkey Enchilada Verde",
  "Turkey Meat Sauce",
  "Turkey Meatloaf",
  "Turkey Meatloaf with Gravy",
  "Turkey Pot Pie",
  "Turkey Salad",
  "Turkey Sausage",
  "Turkey Sausage Link",
  "Turkey Sausage Patty",
  "Turkey Shepherd's Pie with Potatoes",
  "Turkey Taco Meat",
  "Turkey Vegetable with Rice Soup",
  "Turmeric Fried Potatoes",
  "Turmeric Roasted Root Vegetables",
  "Tzatziki (Yogurt and Cucumber Sauce)",
  "Udon Noodles",
  "Ultimate Double Brownie (no garnish)",
  "Vanilla Coconut Overnight Oats",
  "Vanilla Cream Icing",
  "Vanilla Pudding Fruit Parfait",
  "Vanilla Pudding Parfait",
  "Vanilla Quick Bread",
  "Vanilla and Sprinkle Donut",
  'Vegan "Sour Cream"',
  "Vegan Apple Coconut Crisp",
  "Vegan Apple Leek Soup, Coconut",
  "Vegan Apple, Oat, Coconut Muffin",
  "Vegan Banana Muffins",
  "Vegan Bean Bake",
  "Vegan Black Bean Spinach Enchilada",
  "Vegan Boston Baked Beans",
  "Vegan Brownies\t\t\t\t\t",
  "Vegan Chicken Nugget",
  "Vegan Chicken Patty",
  "Vegan Chicken Strips",
  "Vegan Chocolate Cake with Frosting",
  "Vegan Chocolate Chip Cookie",
  "Vegan Chocolate Chip Cookie Mix\t\t\t\t\t",
  "Vegan Coconut and Banana Muffin",
  "Vegan Cornbread",
  "Vegan Cutlet",
  "Vegan Dirty Rice",
  "Vegan Gravy with Roasted Mushrooms",
  "Vegan Green Split Pea Soup",
  "Vegan Mango Mousse, Coconut",
  "Vegan Meat Sauce",
  "Vegan Meatball Sub with Vegan Cheese",
  "Vegan Meatballs",
  "Vegan Meatballs in Marinara",
  "Vegan Oatmeal Raisin Cookie",
  "Vegan Oreo Mousse",
  "Vegan Potato Soup",
  "Vegan Red Velvet Cupcakes Regular size\t\t\t",
  "Vegan Refried Beans",
  "Vegan Stacked Salad",
  "Vegan Strawberry Mousse",
  "Vegan Sweet Potato Oat Cookie",
  "Vegan Tofu Breakfast Skillet",
  "Vegan Tofu Fried Rice",
  "Vegan Two-Grain Bean Soup",
  "Vegan Vanilla Pudding\t\t\t\t\t",
  "Vegan Vegetable Fried Rice",
  "Vegan Vegetable Soup",
  "Vegan Vegetable and Potato Soup",
  "Vegan White Cake\t\t\t\t\t",
  "Vegetable & Rice Pilaf ",
  "Vegetable Beef Soup",
  "Vegetable Crudite",
  "Vegetable Egg Roll",
  "Vegetable Fried Rice",
  "Vegetable Frittata",
  "Vegetable Lasagna",
  "Vegetable Lentil and Spinach Soup",
  "Vegetable Medley",
  "Vegetable Pho",
  "Vegetable Pizza",
  "Vegetable Rice Soup",
  "Vegetable Spring Roll",
  "Vegetable Spring Roll with Sauce",
  "Vegetable Stir Fry",
  "Vegetables",
  "Vegetables for Fajita",
  "Vegetarian Collard Greens",
  "Vegetarian Fried Brown Rice",
  "Veggie Sausage Patty",
  "Veggie Supreme Pizza",
  "Vietnamese Herb Salad",
  "Waffle Fry",
  "Warm Quinoa and Corn Salad",
  "Wheat Hamburger Bun",
  "White Bean & Kale Salad",
  "White Bean Hummus",
  "White Bean and Kale Salad",
  "White Cake",
  "White Cake with Vanilla Icing",
  "White Chocolate Raspberry Biscotti",
  "White Chocolate Raspberry Scone",
  "White Hamburger Bun",
  "White Rice",
  "White Tomato Garlic & Oil Pizza",
  "Whole Baby Carrots",
  "Whole Kernel Corn",
  "Whole Wheat Penne Pasta",
  "Whole Wheat Pita Bread",
  "Whole Wheat Pita Chips",
  "Whole Wheat Rotini Pasta",
  "Yellow Basmati Rice",
  "Yellow Cake",
  "Yellow Rice",
  "Yellow Squash with Red Onions",
  "Yogurt, Strawberry, Greek, Chobani, 5.3 oz",
  "Yogurt, Vanilla, Greek, Chobani, 5.3 oz",
  "Ziti Alfredo",
  "Ziti Pasta",
  "Ziti with Marinara",
  "Zucchini & Yellow Squash",
  "Zucchini Bread",
  "Zucchini and Bulgur Stuffed Eggplant ",
  "Zucchini and Tomatoes",
  "Zucchini, Tomato, Squash and Mushroom",
  "banana pudding",
  "beef barbacoa",
  "carrot and jalapeno salad",
  "chicken and pork apple sausage",
  "chicken tinga",
  "cilantro lime brown rice",
  "cilantro lime rice",
  "farfalle pasta",
  "farro",
  "french toast",
  "ground beef filling",
  "kansas city BBQ sauce",
  "linguine",
  "multigrain bacon pancakes",
  "orange spiced oat and quinoa",
  "parmesan green beans",
  "pico de gallo",
  "pork carnitas tacos",
  "red quinoa",
  "refried black beans",
  "roasted eggplant",
  "shredded hash browns",
  "spicy black beans",
  "spinach with raisins",
  "tofu and black bean scramble",
  "tortilla chips",
  "tri color tortilla chips",
  "wild mushroom risotto",
];

export default uniqueMeals;
